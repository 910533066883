import {faTimes} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React from "react"

interface Props {
  readonly url: string
  readonly visible: boolean
  readonly setVisible: any
}

const VideoPopup: React.FC<Props> = ({url, visible, setVisible}) => (
  <div
    onClick={() => setVisible(false)}
    className={`fixed z-10 inset-0 overflow-y-auto bg-gray-800 bg-opacity-75 ${visible ? "visible" : "invisible"}`}>
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-1/2 bg-white p-1 flex flex-col rounded-sm">
        <div className="text-right">
          <a className="cursor-pointer pt-3 pr-3 pb-6 text-2xl" onClick={() => setVisible(false)}>
            <FontAwesomeIcon icon={faTimes} className="text-base text-tercen-blue hover:text-tercen-blue-hover" />{" "}
          </a>
        </div>
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src={url}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
)

export default VideoPopup
