import React, {useState} from "react"
import {graphql} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import TestimonialList from "../components/testimonials"
import Layout from "../components/layout"
import {CTA, SharpImage, StrapiMixedMedia, StrapiSharpImage} from "../utils/types"
import Navbar from "../components/navbar"
import VideoPopup from "../components/videoPopup"

interface Props {
  readonly data: PageQueryData
}
const popUp = () => {
  console.log("popup")
}

const Index: React.FC<Props> = ({data}) => {
  const siteTitle = data.site.siteMetadata.title,
    signUpURL = "https://tercen.com/_signup",
    googleSignUpURL =
      "https://accounts.google.com/o/oauth2/auth?client_id=653844972403-ve6tjq31861siedt2pqp1h6ilk5kgbvk.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Ftercen.com%2F%3Fauth.provider%3Dgoogle&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline"
  const page = data.strapiHomepage

  return (
    <Layout
      title="The Biomedical Data Analysis Platform"
      keywords={[`tercen`, `data science platform`]}
      description={page.metaDescription}
      hideNavbar={true}>
      <Navbar />
      <header>
        <div className="relative">
          <GatsbyImage
            className=""
            style={{minHeight: "350px", maxHeight: "550px"}}
            image={page.hero.mainImage.localFile?.childImageSharp.gatsbyImageData}
            alt="Hero image"
          />
          <div className="absolute inset-0">
            <div className="container h-full justify-left items-center flex">
              <div className="flex-shrink bg-black bg-opacity-60 py-3 lg:py-6 px-8">
                <h1
                  className="text-white text-4xl lg:text-6xl font-bold"
                  dangerouslySetInnerHTML={{__html: page.hero.mainText}}
                />
                <h2
                  className="text-base lg:text-2xl pt-3 font-medium text-tercen-yellow"
                  dangerouslySetInnerHTML={{__html: page.hero.tagline}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-tercen-blue full-width">
          <div className="container text-white py-10 flex flex-col md:flex-row items-center md:divide-x md:divide-white">
            <div className="px-8 lg:px-16 flex-1 flex justify-center py-3">
              <div className="px-6">
                <div className="p-4 bg-tercen-orange"></div>
              </div>
              <div className="">
                <h4 className="uppercase tracking-wider font-source-sans-pro">{page.introSubtitle}</h4>
                <h3
                  className="text-2xl lg:text-4xl font-bold md:text-right ml-4 pt-2"
                  dangerouslySetInnerHTML={{__html: page.introTitle}}
                />
              </div>
            </div>
            <div className="px-8 lg:px-16 flex-1 py-3">
              <div className="font-source-sans-pro font-semibold" dangerouslySetInnerHTML={{__html: page.introText}} />
            </div>
          </div>
        </div>
      </header>
      <section className="w-full" dangerouslySetInnerHTML={{__html: page.extraHtml}} />
      {page.slides.map((slide: Slide, key: number) => {
        const [popupVisible, setPopupVisible] = useState(false)
        return (
          <section className={"w-full py-4 px-6 " + (key % 2 === 1 ? "" : "bg-gray-100")} key={slide.id}>
            <div
              className={
                "container flex items-center flex-col " +
                (slide.imagePosition === "right" ? "md:flex-row-reverse" : "md:flex-row")
              }>
              <div className={`flex-1 w-full ${slide.image.mime.includes("video") ? "aspect-w-16 aspect-h-9" : ""}`}>
                {slide.image.mime.includes("image") && slide.CTA?.URL.includes("youtube.com") && (
                  <>
                    <a className="cursor-pointer" onClick={() => setPopupVisible(true)}>
                      <GatsbyImage
                        className="border hover:opacity-40 hover:bg-white"
                        image={slide.image.localFile.childImageSharp?.gatsbyImageData}
                        alt="slide image"
                      />
                    </a>
                    <VideoPopup url={slide.CTA?.URL} visible={popupVisible} setVisible={setPopupVisible} />
                  </>
                )}
                {slide.image.mime.includes("image") && !slide.CTA && (
                  <GatsbyImage image={slide.image.localFile.childImageSharp?.gatsbyImageData} alt="slide image" />
                )}
                {slide.image.mime.includes("video") && (
                  <video autoPlay loop muted playsInline>
                    <source src={slide.image.localFile.publicURL} type={slide.image.mime} />
                  </video>
                )}
              </div>
              <div className="flex-1 px-8 py-4 flex justify-center">
                <div className="py-6">
                  <h4 className="font-source-sans-pro uppercase text-tercen-blue font-medium text-base tracking-wider pb-2">
                    {slide.subtitle}
                  </h4>
                  <h3 className="text-4xl pb-6 font-bold text-black">{slide.title}</h3>
                  <div className="font-light text-base" dangerouslySetInnerHTML={{__html: slide.description}} />
                </div>
              </div>
            </div>
          </section>
        )
      })}
      <section className="w-full pt-20">
        <div className="container">
          <h1 className="text-center text-3xl font-bold text-black">Testimonials</h1>
          <div className="mx-6 md:mx-24 py-4">
            <TestimonialList></TestimonialList>
          </div>
        </div>
      </section>
      <section className="w-full pt-20">
        <div className="container">
          <h1 className="text-center text-l font-normal text-black">{page.customerListTitle}</h1>
          <div className="mx-6 text-center md:mx-24 py-4">
            {page.customers.map((customer: Customer, key: number) => {
              return (
                <GatsbyImage
                  className="m-4 hover:opacity-40 hover:bg-white"
                  image={customer.logo.localFile.childImageSharp?.gatsbyImageData}
                  alt=""
                />
              )
            })}
          </div>
        </div>
      </section>
      <section className="w-full pt-20">
        <div className="container text-center px-4 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
          <h1 className="text-center text-3xl font-bold text-black">Sign Up Now</h1>
          <div className="text-center py-12">
            <a
              href={googleSignUpURL}
              className="my-3 border w-full transition duration-500 ease-in-out border-tercen-blue bg-tercen-blue hover:bg-white text-white hover:text-tercen-blue flex flex-row rounded-sm filter drop-shadow-md">
              <div className="bg-white py-1 px-3">
                <svg className="w-4 h-6" viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                    fill="#4285f4"
                  />
                  <path
                    d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                    fill="#34a853"
                  />
                  <path
                    d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                    fill="#fbbc04"
                  />
                  <path
                    d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                    fill="#ea4335"
                  />
                </svg>
              </div>
              <div className="flex-grow py-1 font-medium text-center">Continue with Google</div>
            </a>
            <a
              href={signUpURL}
              className="my-3 border w-full py-1 px-3 transition duration-500 ease-in-out border-tercen-blue bg-white hover:bg-tercen-blue text-tercen-blue hover:text-white flex flex-row rounded-sm filter drop-shadow-md">
              <div className="flex-grow px-4 font-medium text-center">Sign up with email</div>
            </a>
            <p className="text-sm mt-6 mb-2 font-source-sans-pro">Want to chat about data?</p>
            <a
              href="https://calendly.com/tercen-demo/tercen-demo"
              target="_blank"
              className="bg-tercen-green-base transition duration-500 ease-in-out hover:bg-tercen-green-hover text-white py-1 rounded-sm font-medium inline-block w-full filter drop-shadow-md">
              Book a Session
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

interface Feature {
  id: string
  title: string
  tagline: string
  image: StrapiSharpImage
}

interface Slide {
  id: string
  title: string
  subtitle: string
  description: string
  CTA?: CTA
  image: StrapiMixedMedia
  imagePosition: string
}

interface Customer {
  logo: StrapiSharpImage
  name?: string
  website?: string
}

interface Homepage {
  metaDescription?: string
  hero: {
    mainText: string
    tagline: string
    mainImage: StrapiSharpImage
  }
  introTitle: string
  introSubtitle: string
  introText: string
  introCTA: CTA
  featureSet: Array<Feature>
  slides: Array<Slide>
  customerListTitle: string
  customers: Array<Customer>
  extraHtml: string
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  strapiHomepage: Homepage
  videoLinkButton: SharpImage
}

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    strapiHomepage {
      metaDescription
      extraHtml
      hero {
        mainText
        tagline
        mainImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH)
            }
          }
        }
      }
      introTitle
      introSubtitle
      introText
      introCTA {
        text
        class
        URL
      }
      featureSet {
        id
        title
        tagline
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 227, quality: 60)
            }
          }
        }
      }
      slides {
        id
        title
        subtitle
        description
        imagePosition
        image {
          mime
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 700, quality: 80)
            }
          }
        }
        CTA {
          text
          URL
        }
      }
      customerListTitle
      customers {
        id
        logo {
          mime
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(height: 100, quality: 80)
            }
          }
        }
      }
    }
    videoLinkButton: file(relativePath: {eq: "Playwhatis_D.png"}) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`
export default Index
